


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
.el-descriptions /deep/ .el-descriptions__title {
  font-size: 1.25rem;
}
.el-descriptions /deep/ .el-descriptions-item__container {
  font-size: 1rem;
}
.el-descriptions
  .el-descriptions__body
  .el-descriptions__table
  /deep/
  .el-descriptions-row {
  margin-top: 10px;
}
